import React from 'react';
import ImageContainer from './ImageContainer';
import { FormattedMessage } from 'react-intl';
import { imageUrl } from '../shared';

const Layout = ({bannerImage, bannerText, abstractLayout, children}) => {
  const bannerUrl = abstractLayout ? bannerImage : imageUrl('banners', `${bannerImage}.jpg`);

  return (
    <React.Fragment>
      <div className='relative overflow-hidden'>
        <ImageContainer className='bg-cover bg-center filter brightness-50 blur-sm -m-6 z-0 md:h-48' imageUrl={bannerUrl}/>
        <div className='absolute inset-0 flex justify-center items-center text-center z-10'>
          <div className='text-7xl text-ghost self-center md:text-5xl'>
            {abstractLayout ? <span>{bannerText}</span> : <FormattedMessage id={bannerText}/>}
          </div>
        </div>
      </div>
      {children}
    </React.Fragment>
  );
}

export default Layout;
