import entertainersDream from './entertainersDream';
import waterfrontRetreat from './waterfrontRetreat';
import progreston from './progreston';
import poinsettia from './poinsettia';

const projects = [
  entertainersDream,
  waterfrontRetreat
];

const comingSoon = [
  progreston,
  poinsettia
];

export {
  projects,
  comingSoon
};
