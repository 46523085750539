import React from 'react';
import { Outlet, NavLink, Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../components';
import Logo from '../../assets/logo.svg'

const NavbarLink = ({path, message}) => {
  const linkClassName = 'border-b-2 text-ghost ease-transition hover:border-feldgrau cursor-pointer'

  return (
    <NavLink to={path} className={({isActive}) => isActive ? `${linkClassName} border-ghost` : `${linkClassName} border-transparent`}>
      <FormattedMessage id={message}/>
    </NavLink>
  );
};

const SiteLinks = () => {
  return (
    <React.Fragment>
      <NavbarLink path='/about' message='navbar.About'/>
      <NavbarLink path='/process' message='navbar.Process'/>
      <NavbarLink path='/portfolio' message='navbar.Portfolio'/>
      <NavbarLink path='/contact' message='navbar.Contact'/>
    </React.Fragment>
  )
}

const Navbar = () => {
  let navigate = useNavigate();

  return (
    <div className='bg-raisin p-4'>
      <div className='grid grid-cols-6'>
        <div className='my-auto md:col-span-3'>
          <Link to='/'>
            <div className='w-16'>
              <img src={Logo} alt='Forj Logo'/>
            </div>
          </Link>
        </div>
        <div className='flex col-span-4 justify-center items-center space-x-24 lg:col-span-3 lg:space-x-16 md:hidden'>
          <SiteLinks/>
        </div>
        <div className='m-auto lg:col-span-2 md:col-span-3 lg:mr-0'>
          <Button text="navbar.Get a Quote" onClick={() => navigate('/contact')}>
            <FormattedMessage id='navbar.Get a Quote'/>
          </Button>
        </div>
      </div>
      <div className='hidden mt-4 justify-center items-center space-x-24 md:flex md:space-x-12 sm:space-x-6'>
        <SiteLinks/>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className='bg-raisin text-ghost flex justify-between text-center px-4 p-8 md:flex-col md:space-y-3 md:p-4'>
      <div>
        <FormattedMessage id='COPYRIGHT'/>
      </div>
      <div className='flex space-x-4 text-xl md:justify-center'>
        <a href="https://linkedin.com/in/forjcustomhomes/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon="fa-brands fa-linkedin"/>
        </a>
        <a href="https://instagram.com/forjcustomhomes/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon="fa-brands fa-instagram"/>
        </a>
        <a href="mail:ben@forjcustomhomes.ca">
          <FontAwesomeIcon icon="fa-solid fa-envelope"/>
        </a>
        <a href="tel:+14165094259">
          <FontAwesomeIcon icon="fa-solid fa-phone"/>
        </a>
      </div>
    </div>
  );
};

const Root = () => {
  return (
    <div className='bg-ghost text-raisin font-lato'>
      <Navbar/>
      <div className='flex-grow'>
        <Outlet/>
      </div>
      <Footer/>
    </div>
  );
}

export default Root;
