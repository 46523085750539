import React from 'react';

const AddressMap = () => {
  return (
    <div className='h-80'>
      <iframe title='map' className='w-full h-full' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.604732827422!2d-79.86989528459476!3d43.635585161587706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b6cda11258947%3A0x7ed66112b40cbe1a!2s160%20Morningside%20Dr%2C%20Norval%2C%20ON%20L0P%201K0!5e0!3m2!1sen!2sca!4v1638717079511!5m2!1sen!2sca' allowFullScreen='' loading='lazy'/>
    </div>
  );
}

export default AddressMap;
