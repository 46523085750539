import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, ImageContainer, TextContainer } from 'components';
import { Link } from 'react-router-dom';
import { imageUrl } from 'shared';
import { projects, comingSoon } from 'api/portfolio'

const ProjectCard = ({project}) => {
  return (
    <Link key={project.slug} to={`/portfolio/${project.slug}`}>
      <ImageContainer className='bg-cover bg-center' imageUrl={imageUrl('portfolio', project.image)}>
        <div className='bg-ghost flex flex-col h-full opacity-0 hover:opacity-80 transition duration-300 ease-in-ease-out md:opacity-70 md:bg-transparent md:justify-end'>
          <div className='m-auto text-center md:m-0 md:bg-ghost md:pt-2 md:mx-4 md:mb-4'>
            <p className='font-lato font-bold text-xl mb-1'>{project.name}</p>
            <p className='text-raisin-light mb-4 indent-8'>{project.location}</p>
          </div>
        </div>
      </ImageContainer>
    </Link>
  );
}

const Portfolio = () => {
  return (
    <Layout bannerImage='portfolio' bannerText='portfolio.Portfolio'>
      <TextContainer>
        <div className='font-cormorant text-center text-4xl leading-relaxed mt-20 mb-24 md:mt-8 md:mb-8'>
          <FormattedMessage id='portfolio.OPENING'/>
        </div>
      </TextContainer>
      <div className='grid grid-cols-2 gap-12 px-32 pb-8 lg:px-8 md:px-8 sm:px-4 md:grid-cols-1'>
        {projects.map((project) => <ProjectCard project={project}/>)}
      </div>
      <div className='bg-feldgrau'>
        <div className='py-8 px-64 lg:px-32 md:px-24 sm:p-8'>
          <div className='font-josefin text-center font-bold text-ghost text-4xl mb-4'>
            <FormattedMessage id='portfolio.Coming Soon'/>
          </div>
          <div className='flex sm:flex-col justify-center divide-x-2 divide-ghost mx-auto mt-8 sm:divide-x-0 sm:divide-y sm:w-full sm:px-8 sm:mx-0 sm:mt-4'> 
            {comingSoon.map((project) => (
              <div key={project.slug} className='px-16 flex flex-col sm:px-0 sm:py-2 sm:flex-row text-center sm:justify-between'>
                <span className='text-xl text-ghost'>{project.name}</span>
                <span className='text-sm text-ghost opacity-60 italic self-center'>{project.location}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Portfolio;
