import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, TextContainer } from 'components';
import { imageUrl } from 'shared';

const StepBody = ({stepNumber, ltr}) => {
  return (
    <div className={`z-10 lg:mb-12 ${ltr ? '' : 'col-start-1'}`}>
      <div className={`bg-timberwolf px-6 py-8 shadow-lg ${ltr ? '-ml-36' : '-mr-36'} lg:px-10 lg:mx-0 md:shadow-none`}>
        <div className='font-josefin font-bold text-feldgrau text-3xl mb-4'>
          <FormattedMessage id={`process.steps.step_${stepNumber}.title`}/>
        </div>
        <div className='mb-4 font-light leading-relaxed text-xl'>
          <FormattedMessage id={`process.steps.step_${stepNumber}.body`}/>
        </div>
      </div>
    </div>
  );
}

const Step = ({stepNumber, ltr}) => {
  return (
    <div className='grid grid-cols-3 grid-flow-col-dense lg:flex items-center lg:flex-col'>
      <React.Fragment>
        <img
          src={imageUrl('process', `step_${stepNumber}.jpg`)} alt={`Step ${stepNumber}`}
          className={`w-full h-[30rem] object-cover shadow-lg col-span-2 ${ltr ? '' : 'col-start-2'} md:h-auto`}
        />
        <StepBody stepNumber={stepNumber} ltr={ltr}/>
      </React.Fragment>
    </div>
  );
}

const Process = () => {
  return (
    <Layout bannerImage='process' bannerText='process.Process'>
      <TextContainer>
        <div className='font-cormorant text-center text-4xl leading-relaxed mt-20 mb-24 md:mt-8 md:mb-8'>
          <FormattedMessage id='process.OPENING'/>
        </div>
      </TextContainer>
      <div className='space-y-16 pb-8 px-48 2xl:px-20 xl:px-8 lg:px-8 sm:p-4 lg:space-y-8'>
        {[...Array(5)].map((_, index) => <Step key={index} stepNumber={index + 1} ltr={index % 2 !== 0}/>)}
      </div>
    </Layout>
  );
}

export default Process;
