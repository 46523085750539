import React, { useState, useEffect } from 'react';
import { TextInput, TextArea, Button } from 'components';
import { FormattedMessage } from 'react-intl';
import emailjs from '@emailjs/browser';
import _ from 'lodash';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [sendDisabled, setSendDisabled] = useState(true);

  useEffect(() => {
    if(_.isEmpty(name) || _.isEmpty(email) || _.isEmpty(phoneNumber) || _.isEmpty(subject) || _.isEmpty(message)) {
      setSendDisabled(true);
    } else {
      setSendDisabled(false);
    }
  }, [name, email, phoneNumber, subject, message])

  const clearFields = () => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setSubject('');
    setMessage('');
  }

  const sendEmail = () => {
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_KEY, process.env.REACT_APP_EMAILJS_TEMPLATE_KEY, {
      from_name: name,
      from_email: email,
      from_phone: phoneNumber,
      subject: subject,
      message: message
    }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
    clearFields();
  };

  return (
    <div>
      <div className='space-y-4'>
        <TextInput label='contact.Name' onChange={(e) => setName(e.target.value)} value={name}/>
        <TextInput label='contact.Email' onChange={(e) => setEmail(e.target.value)} value={email}/>
        <TextInput label='contact.Phone Number' onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}/>
        <TextInput label='contact.Subject' onChange={(e) => setSubject(e.target.value)} value={subject}/>
        <TextArea label='contact.Message' onChange={(e) => setMessage(e.target.value)} value={message}/>
      </div>
      <div className='mt-4 float-right'>
        <Button onClick={() => sendEmail()} disabled={sendDisabled}>
          <FormattedMessage id='contact.Send'/>
        </Button>
      </div>
    </div>
  );
}

export default ContactForm;
