import React from 'react';

const Button = ({onClick, disabled, children}) => {
  const _onClick = async (e, callback) => {
    e.preventDefault();
    await Promise.resolve(callback(e));
  };

  return (
    <button
      className='font-josefin font-bold bg-benjamindigo p-2 px-6 ease-transition hover:bg-benjamindigo-hover active:bg-benjamindigo-active disabled:bg-timberwolf disabled:text-raisin-light'
      onClick={async (e) => await _onClick(e, onClick)}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
