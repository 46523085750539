import React from 'react';
import { FormattedMessage } from 'react-intl';

const TextArea = ({value, onChange, label, errorMessage}) => {
  return (
    <div>
      <div className='flex justify-between'>
        <div className='font-light leading-relaxed text-2xl'>
          <FormattedMessage id={label}/>
        </div>
      </div>
      <textarea
        className='w-full outline-none border-2 border-border p-1 ease-transition focus:border-benjamindigo'
        onChange={(e) => onChange(e)}
        value={value}
      />
    </div>
  );
}

export default TextArea;
