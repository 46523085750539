import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'components';
import { imageUrl } from 'shared';
import { projects } from 'api/portfolio'

const Project = () => {
  let { slug } = useParams();
  const [project] = useState(projects.find(p => p.slug === slug));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  console.log(project);

  return (
    <Layout bannerImage={imageUrl('portfolio', project.image)} bannerText={project.name} abstractLayout>
      <div className='columns-3 gap-4 p-4 space-y-4 md:columns-2 sm:columns-1'>
        {project.gallery.map((photo, index) => (
          <img key={index} className='w-full' src={imageUrl('portfolio', photo.path)} alt='alt'/>
        ))}
      </div>
    </Layout>
  );
}

export default Project;
