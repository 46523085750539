import dashify from 'dashify';

const poinsettia = {
  name: 'Poinsettia',
  slug: dashify('Poinsettia'),
  location: 'Port Charlotte, FL, U.S.A',
  comingSoon: true
};

export default poinsettia;
