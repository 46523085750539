import React from 'react';
import ReactDOM from 'react-dom/client';
import router from './router';
import { RouterProvider } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import English from './locales/en.json';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faPhone, faBuilding, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import './style/index.css';
import './style/fonts.css';

library.add(faInstagram, faLinkedin, faEnvelope, faPhone, faBuilding, faExclamationTriangle)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <IntlProvider messages={English} locale='en' defaultLocale='en'>
      <RouterProvider router={router} />
    </IntlProvider>
  </React.StrictMode>
);
