import React from 'react';

const TextContainer = ({children}) => {
  return (
    <div className='py-8 px-72 lg:px-32 md:px-24 sm:p-8'>
      {children}
    </div>
  );
}

export default TextContainer;
