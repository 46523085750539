import React from 'react';

const ImageContainer = ({className, imageUrl, children}) => {
  return (
    <div className={`h-96 ${className}`} style={{ backgroundImage: `url(${imageUrl})` }}>
      {children}
    </div>
  );
}

export default ImageContainer;
