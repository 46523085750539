import dashify from 'dashify';

const waterfrontRetreat = {
  name: 'Waterfront Retreat',
  slug: dashify('Waterfront Retreat'),
  location: 'Fort Myers, FL, U.S.A',
  image: 'waterfront_retreat/outside_front.jpg',
  gallery: [
    { path: 'waterfront_retreat/outside_front.jpg' },
    { path: 'waterfront_retreat/foyer.jpg' },
    { path: 'waterfront_retreat/kitchen.jpg' },
    { path: 'waterfront_retreat/bathroom.jpg' },
    { path: 'waterfront_retreat/poolside.jpg' },
    { path: 'waterfront_retreat/barbeque.jpg' }
  ]
};

export default waterfrontRetreat;
