import dashify from 'dashify';

const entertainersDream = {
  name: 'Entertainer\'s Dream',
  slug: dashify('Entertainer\'s Dream'),
  location: 'Acton, ON, Canada',
  image: 'entertainers_dream/outside_front_day.jpg',
  gallery: [
    { path: 'entertainers_dream/outside_front_day.jpg' },
    { path: 'entertainers_dream/outside_front_night.jpg' },
    { path: 'entertainers_dream/poolside_night.jpg' },
    { path: 'entertainers_dream/poolside_day.jpg' },
    { path: 'entertainers_dream/court_day.jpg' },
    { path: 'entertainers_dream/firepit_night.jpg' },
    { path: 'entertainers_dream/cover_porch_night.jpg' },
    { path: 'entertainers_dream/foyer.jpg' },
    { path: 'entertainers_dream/dining_room.jpg' },
    { path: 'entertainers_dream/living_room.jpg' },
    { path: 'entertainers_dream/kitchen.jpg' },
    { path: 'entertainers_dream/family_room.jpg' },
    { path: 'entertainers_dream/master_bedroom.jpg' },
    { path: 'entertainers_dream/master_bath.jpg' },
    { path: 'entertainers_dream/master_walk_in.jpg' },
    { path: 'entertainers_dream/main_stairwell.jpg' },
    { path: 'entertainers_dream/first_bedroom.jpg' },
    { path: 'entertainers_dream/first_ensuite_bath.jpg' },
    { path: 'entertainers_dream/second_bedroom.jpg' },
    { path: 'entertainers_dream/second_ensuite_bath.jpg' },
    { path: 'entertainers_dream/lounge.jpg' },
    { path: 'entertainers_dream/basement.jpg' },
    { path: 'entertainers_dream/rec_room.jpg' },
    { path: 'entertainers_dream/rec_room_tvs.jpg' },
    { path: 'entertainers_dream/wine_cellar.jpg' },
    { path: 'entertainers_dream/bowling_alley.jpg' },
    { path: 'entertainers_dream/bowling_alley_lanes.jpg' },
    { path: 'entertainers_dream/theater.jpg' }
  ]
};

export default entertainersDream;
