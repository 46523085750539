import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, TextContainer } from '../../components';

const PageSection = ({title, paragraphs}) => {
  return (
    <div className='mb-12'>
      <div className='font-josefin font-bold text-feldgrau text-4xl mb-4'>
        <FormattedMessage id={title}/>
      </div>
      {paragraphs.map((paragraph) => (
        <div className='mb-4 font-light leading-relaxed text-2xl'>
          <FormattedMessage id={paragraph}/>
        </div>
      ))}
    </div>
  );
}

const About = () => {
  return (
    <Layout bannerImage='about' bannerText='about.About'>
      <TextContainer>
        <div className='mt-20 mb-24 md:mt-8 md:mb-8'>
          <div className='font-cormorant text-center text-4xl leading-relaxed mb-1'>
            <FormattedMessage id='about.OPENING'/>
          </div>
          <div className='text-right text-raisin-light italic'>
            <FormattedMessage id='about.- Ben Moreira, Owner'/>
          </div>
        </div>
        <PageSection
          title='about.builder.The Builder'
          paragraphs={['about.builder.PARAGRAPH_1']}
        />
        <PageSection
          title='about.company.The Company'
          paragraphs={['about.company.PARAGRAPH_1']}
        />
        <PageSection
          title='about.approach.Our Approach'
          paragraphs={['about.approach.PARAGRAPH_1']}
        />
      </TextContainer>
    </Layout>
  );
}

export default About;
