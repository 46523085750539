import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl';
import { Layout, TextContainer } from 'components';
import AddressMap from './AddressMap';
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <Layout bannerImage='contact' bannerText='contact.Contact'>
      <TextContainer>
        <div className='font-josefin font-bold text-feldgrau text-4xl mb-4'>
          <FormattedMessage id='contact.Get In Touch'/>
        </div>
        <div className='grid grid-cols-12 gap-16 md:flex md:flex-col'>
          <div className='col-span-4'>
            <div className='space-y-2'>
              <div className='flex'>
                <div className='mb-4 font-light leading-relaxed text-2xl'>
                  <FontAwesomeIcon className='mr-2' icon='fa-solid fa-envelope'/>
                  <a href='mail:ben@forjcustomhomes.ca'>{'ben@forjcustomhomes.ca'}</a>
                </div>
              </div>
              <div className='flex'>
                <div className='mb-4 font-light leading-relaxed text-2xl'>
                  <FontAwesomeIcon className='mr-2' icon='fa-solid fa-phone'/>
                  <a href='tel:+14165094259'>{'(416) 509-4259'}</a>
                </div>
              </div>
              <div className='flex'>
                <div className='flex mb-4 font-light text-2xl'>
                  <FontAwesomeIcon className='mr-2' icon='fa-solid fa-building'/>
                  <span className='whitespace-pre'>{`160 Morningside Dr.\nNorval, ON`}</span>
                </div>
              </div>
              <AddressMap/>
            </div>
          </div>
          <div className='col-span-8'>
            <ContactForm/>
          </div>
        </div>
      </TextContainer>
    </Layout>
  );
}

export default Contact;
