import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Root, About, Process, Portfolio, Project, Contact } from './containers';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    children: [
      {
        path: '/',
        element: <Navigate to='/about' replace/>
      },
      {
        path: '/about',
        element: <About/>
      },
      {
        path: '/process',
        element: <Process/>
      },
      {
        path: '/portfolio',
        element: <Portfolio/>
      },
      {
        path: '/portfolio/:slug',
        element: <Project/>
      },
      {
        path: '/contact',
        element: <Contact/>
      }
    ]
  },
]);

export default router;
