import dashify from 'dashify';

const progreston = {
  name: 'Progreston',
  slug: dashify('Progreston'),
  location: 'Burlington, ON, Canada',
  comingSoon: true
};

export default progreston;
